var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t("cip.plat.app.appVersion.title.indexHeadTitle"),
        },
        on: { "head-add": _vm.handleAdd, "head-romve": _vm.headRomve },
      }),
      _c("grid-head-layout", {
        ref: "searchFrom",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.searchChange,
          "grid-head-empty": _vm.searchReset,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.tableOptions,
          "table-data": _vm.dataParent,
          "table-loading": _vm.loading,
          "data-total": _vm.dataTotal,
          page: _vm.page,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "page-size-change": _vm.onLoad,
          "page-current-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
          "grid-row-detail-click": _vm.handleView,
          "grid-edit": _vm.handleEdit,
          "grid-romve": _vm.rowDel,
        },
        scopedSlots: _vm._u([
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                row.enableFlag != "enable"
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.rowValid(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("cip.cmn.btn.enable")) + "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }