<template>
  <div>
    <!-- 头部样式 左侧为功能title  右侧为业务按钮 -->
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.app.appVersion.title.indexHeadTitle')" @head-add="handleAdd"
                 @head-romve="headRomve"></head-layout>
    <!-- 对表单数据的搜索样式 -->
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
    <!-- 表格 -->
    <grid-layout ref="gridLayOut" :tableOptions="tableOptions" :table-data="dataParent" :table-loading="loading"
                 :data-total="dataTotal" :page="page" @page-size-change="onLoad" @page-current-change="onLoad"
                 @page-refresh-change="onLoad" @gird-handle-select-click="selectionChange" :gridRowBtn="gridRowBtn"
                 @grid-row-detail-click="handleView"
                 @grid-edit="handleEdit" @grid-romve="rowDel">


      <template #customBtn="{row}">
        <el-button
          v-if="row.enableFlag != 'enable'"
          type="text"
          size="small"
          @click="rowValid(row)"
        >{{ $t('cip.cmn.btn.enable') }}
        </el-button>
      </template>
<!--      <template #customBtn="{row}">-->
<!--        <el-button-->
<!--          v-if="row.status == 2"-->
<!--          type="text"-->
<!--          size="small"-->
<!--          @click="rowValid(row)"-->
<!--        >{{"已启用"}}-->
<!--        </el-button>-->
<!--      </template>-->
    </grid-layout>

  </div>
</template>
<script>
import {getList,remove,getValid,getQRcode} from "@/api/system/appVersion";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import {mapGetters} from "vuex";

export default {
  components:{HeadLayout,GridLayout},
  name: "index",
  data() {
    return {
      //表格行按钮

      searchForm:[],
      selectionList:[],

      searchColumns: [
        {
          label: "",
          span: 4,
          type:"input",
          prop: "versionCode",
          placeholder: this.$t("cip.plat.app.appVersion.field.versionCode"),
        },
        {
          label: "",
          span: 4,
          type:"input",
          prop: "versionName",
          placeholder: this.$t("cip.plat.app.appVersion.field.versionName"),
        },
      ],
      dataParent: [],//表格数据
      dataTotal: 0,//总数据量
      tableOptions: {
        menuWidth: 250,
        linklabel: "verCode",
        column: [
          {
            label: this.$t('cip.plat.app.appVersion.field.versionCode'),
            headerAlign: "center",
            prop: "versionCode",
            width: 100,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.appVersion.field.versionName'),
            prop: "versionName",
            headerAlign: "center",
            width: 100,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.appVersion.field.versionInfo'),
            prop: "versionInfo",
            headerAlign: "center",
            width: 400,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.appVersion.field.downloadUrl'),
            prop: "downloadUrl",
            headerAlign: "center",
            align: "left",
            overHidden: true,
          },
        ]
      },
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        pageSizes: [10, 20,30, 40, 50, 100],
        currentPage: 1,
        total: 0
      },
      data: []
    }
  },
  computed:{
    ...mapGetters(["userInfo", "permission", 'colorName']),
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.appVersion_add) {
        result.push({
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.appVersion_delete) {
        result.push({
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.appVersion_edit) {
        result.push({
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          type: "text",
          icon: ""
        });
      }
      if (this.permission.appVersion_delete) {
        result.push({
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          type: "text",
          icon: ""
        });
      }
      return result
    },
  },
  methods: {
    headRomve() {
      this.handleDelete()
    },
    //新增
    handleAdd() {
      this.$router.push({
        path: '/system/appVersion/add',
        query: {
          type: 'add',
        }
      });
    },
    //修改
    handleEdit(row) {
      this.$router.push({
        path: '/system/appVersion/edit',
        query: {
          id: row.id,
          type: 'edit',
        }
      })
    },
    rowDel(row) {
      this.$confirm(this.$t(`cip.cmn.msg.warning.delWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    rowValid(row) {
      getValid(row).then(res => {
        this.onLoad(this.page, {});
        this.$message({
          type: "success",
          message:  this.$t('cip.cmn.msg.success.enableSuccess')

        });
      })
      getQRcode();
    },
    handleView(row) {
      this.$router.push({
        path: '/system/appVersion/view',
        query: {
          id: row.id,
          type: 'view',
        }
      })
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(page.currentPage, page.pageSize, this.query).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.dataTotal = data.total;
        this.dataParent = data.records;
        this.loading = false;
        this.selectionClear();
      });
    }
  },
  created(){
    this.onLoad(this.page);
  }
}
</script>

<style scoped>

</style>
